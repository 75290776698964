.power {
    display: block;
    padding-bottom: 113px;
    .container{
        max-width: 1320px;
        width: 100%;
        padding-right: var(--bs-gutter-x,.75rem);
        padding-left: var(--bs-gutter-x,.75rem);
        margin-right: auto;
        margin-left: auto;
        .row{
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;
            display: flex;
            flex-wrap: wrap;
            margin-top: calc(-1 * var(--bs-gutter-y));
            margin-right: calc(-.5 * var(--bs-gutter-x));
            margin-left: calc(-.5 * var(--bs-gutter-x));
            h2{
                font-size: 40px;
                margin-bottom: 78px;
                line-height: 110%;
                font-weight: 600;
                
            }
           .overview{
            font-size: 16px;
            line-height: 26px;
            opacity: .6;
           } 
           .col-12.col-lg-5{
                padding: 0px 20px;
            }
        }
        .justify-content-center{
            justify-content: center!important;
        }
        .image{
            display: flex;
            justify-content: center;
        }
    }
}