.banner {
    display: block;
    padding: 160px 0 83px 0;
    .container{
        max-width: 1320px;
        width: 100%;
        padding-right: var(--bs-gutter-x,.75rem);
        padding-left: var(--bs-gutter-x,.75rem);
        margin-right: auto;
        margin-left: auto;
        .row{
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;
            display: flex;
            flex-wrap: wrap;
            margin-top: calc(-1 * var(--bs-gutter-y));
            margin-right: calc(-.5 * var(--bs-gutter-x));
            margin-left: calc(-.5 * var(--bs-gutter-x));
            .overview{
                margin-top: 33px;
                font-size: 16px;
                line-height: 150%;
                opacity: .8;
            }
        }
        .banner-content{
            padding-bottom: 75px;
            color: #0d0d0d;
            h1 {
                color: #0d0d0d;
                font-family: Lexend;
                line-height: 110%;
                font-weight: 700;
                font-size: 68px;
            }
        }
    }
}
.justify-content-center{
    justify-content: center!important;
}
.align-self-center {
    align-self: center!important;
}
.image-full{
    padding: 12px;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    img{
        vertical-align: middle;
        max-width: 100%;
        height: auto;
    }
}
.col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
}
.col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
}