.project-detail-container {
  font-family: inherit;
  margin-top: 64px;
}
.project-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
  width: 100%;

  @media screen and (max-width: 576px) {
    min-height: 300px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}
.project-desc {
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 576px) {
    height: 100%;
  }

  background-color: rgba(0, 0, 0, 0.5);
  padding: 80px 0;
  width: 100%;
  .project-title {
    font-size: 48px;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;

    @media screen and (max-width: 576px) {
      font-size: 25px;
    }
  }
  .project-short-desc {
    max-width: 1000px;
    padding: 10px 30px 0 30px;
    text-align: center;
    font-size: 16px;
    line-height: 1.2;

    @media screen and (max-width: 576px) {
      font-size: 13px;
    }

    a {
      // text-decoration: none;
    }
  }
  .project-categories {
    margin-top: 10px;
  }
}

.project-details {
  font-size: 16px;
  margin: 60px 0;
  color: #6d6d6d;
  p + p,
  h4 {
    margin-top: 10px;
  }
  p {
    line-height: 1.5;
  }

  ul {
    margin-left: 20px;
  }
}

.project-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-right: -15px;
  margin-left: -15px;

  font-family: inherit;
  font-weight: 400;
  margin: 100px 0;
}
.project-background {
  border: 1px solid #f1f1f1;
  border: radius 4px;
  background-color: #fff !important;
  padding: 30px 20px;
  font-size: 16px;

  flex: 50%;
  a {
    color: inherit;
  }
  h3 {
    font-size: 30px;

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }
  }
  p {
    font-size: 16px;
  }

  ul {
    margin-left: 30px;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    flex: 100%;
  }
}
.what-we-did {
  flex: 50%;
  border: 1px solid #f1f1f1;
  border: radius 4px;
  background-color: rgba($color: #2daa67, $alpha: 0.9) !important;
  color: #fff;
  padding: 30px 20px;
  font-size: 16px;

  h3 {
    font-size: 30px;
    @media screen and (max-width: 576px) {
      font-size: 20px;
    }
  }
  p {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    flex: 100%;
  }
}

.project-results {
  width: 70%;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: inherit;

  h3 {
    font-size: 50px;
    font-weight: 500;
  }
  p {
    font-size: 16px;
  }
}

.another-projects {
  width: 100%;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h3 {
    font-size: 40px;
    font-weight: 500;
  }
}

.project-cards {
  width: 100%;
  margin: 30px;
  display: flex;
  gap: 30px;

  @media screen and (max-width: 678px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.another-project-card {
  background: #fff;
  -webkit-box-shadow: 0px 0px 5px 1px #e1e1e1;
  box-shadow: 0px 0px 5px 1px #e1e1e1;
  width: 50%;
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1172px) {
    flex-direction: column;
  }

  @media screen and (max-width: 678px) {
    width: 100%;
  }

  @media screen and (min-width: 320px) {
    height: auto;
  }
  @media screen and (min-width: 768px) {
    height: 360px;
  }

  @media screen and (min-width: 1200px) {
    height: 400px;
  }

  &__image {
    overflow: hidden;
    float: left;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 1172px) {
      height: 200px;
      width: 100%;
    }

    // @media screen and (min-width: 320px) {
    //   float: none;
    //   width: 100%;
    // }
    // @media screen and (min-width: 768px) {
    //   float: left;
    //   width: 35%;
    //   height: 360px;
    // }
    // @media screen and (min-width: 1200px) {
    //   height: 400px;
    //   width: 45%;
    // }

    & > a {
      width: 100%;
      height: 100%;
    }

    #image-vertical {
      vertical-align: middle;
      border-style: none;
      max-width: 100%;
      height: 100%;
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;

      @media screen and (max-width: 1172px) {
        height: 200px;
        width: 100%;
      }
      
      @media screen and (min-width: 320px) {
        display: block;
      }
      @media screen and (min-width: 768px) {
        display: block;
      }

      @media screen and (min-width: 980px) {
        display: block;
      }
    }
  }

  &__content {
    padding: 22px;
    float: left;
    @media screen and (min-width: 1200px) {
      min-height: 360px;
      height: min-content;
      width: 55%;
    }

    @media screen and (min-width: 768px) {
      width: 65%;
      float: left;
      height: 300px;
    }
    @media screen and (min-width: 320px) {
      float: none;
      width: 100%;
    }

    & > a {
      text-transform: uppercase;
      color: #2daa67;
      text-decoration: none;
      font-weight: 400;
    }
  }

  &__desc {
    color: #6d6d6d;
    margin-bottom: 10px;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 30px;
    @media screen and (max-width: 1172px) {
      font-size: 20px;
    }
  }
}
.company-link {
  color: inherit;
}
