$_dark: (
   "700": #f7f7f7,
   "500": #b7b7b7,
   "300": #e0e0e0,
   "100": #282e3e,
   "50": rgba(1, 1, 1, 0.05),
   "base": #010101,
);

$_light: (
   "500": rgba(255, 255, 255, 0.5),
   "100": rgba(255, 255, 255, 0.15),
   "base": #ffffff,
);

$colors: (
   "dark": $_dark,
   "light": $_light,
) !default;

@function color($color, $key) {
   @return var(#{--#{$color}}-#{$key});
}
@function colorOrg($color, $key) {
   $content: map-get($colors, $color);
   @if (map-has-key($content, $key)) {
      $result: map-get($content, $key);
      @return $result;
   }
   @return "";
}

:root {
   @each $color, $value in $colors {
      @each $shade, $value in map-get($colors, $color) {
         $jo: --#{$color};
         #{$jo}-#{$shade}: #{colorOrg($color, $shade)};
      }
   }
}

@each $color, $value in $colors {
   @each $shade, $value in map-get($colors, $color) {
      .text-#{$color}-#{$shade} {
         color: #{color($color, $shade)};
      }
      .bg-#{$color}-#{$shade} {
         background-color: #{color($color, $shade)};
      }
   }
}

// usage
$color-black: color("dark", "base");
$color-black_05: color("dark", "50");
$color-white: color("light", "base");
$color-white_50: color("light", "500");
$color-white_15: color("light", "100");

// variables
$font-main: "Inter";
