.layout_button {
   margin-right: 20px;
   width: 80px;
   height: 40px;
   color: black;
 
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }
 .layout_button:hover {
   border: 1px solid #ff5f73;
   border-radius: 24px;
   background-color: #ff5f73;
   color:white;
 }