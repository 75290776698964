header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}
h1 {
	color: white;
}
.link-href {
	text-decoration: none;
	color: white;
	font-size: 14px;
	width: 64px;
	height: 24px;
	padding: 10px 20px;
}
.wrapper {
	display: flex;
	flex-direction: row;

	justify-content: space-between;
	align-items: center;
	background-color: black;

	position: relative;
	width: 100%;
	height: 80px;

	margin: 0px auto;
	top: 0px;
	right: 0px;
	left: 0px;
}
.menu_main {
	margin-right: 20px;
}
.bout-us {
	text-decoration: none;
	font-size: 14px;
	background-color: #2daa67;
	width: 133px;
	height: 48px;
	color: white;
	border-radius: 24px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	-ms-border-radius: 24px;
	-o-border-radius: 24px;
	flex-direction: column;
	align-items: center;
	padding: 12px 24px;
}
