.why-client-love {
    display: block;
    padding-bottom: 113px;
    .container{
        max-width: 1320px;
        width: 100%;
        padding-right: var(--bs-gutter-x,.75rem);
        padding-left: var(--bs-gutter-x,.75rem);
        margin-right: auto;
        margin-left: auto;
        .row{
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;
            display: flex;
            flex-wrap: wrap;
            margin-top: calc(-1 * var(--bs-gutter-y));
            margin-right: calc(-.5 * var(--bs-gutter-x));
            margin-left: calc(-.5 * var(--bs-gutter-x));
            h2{
                margin-top: 20%;
                margin-bottom: 78px;
                line-height: 110%;
                font-weight: 600;
                font-size: 56px;
                margin-bottom: 37px; 
            }
           .overview{
            font-size: 16px;
            line-height: 26px;
            opacity: .6;
           } 
           .col-12.col-lg-5{
                padding: 0px 40px;
            }
        }
        .why-client-love-content{
            // position: absolute;
            // bottom: 0;
        }
        .image{
            width: calc(50% - 15px);
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 50%;
        }
    }
}
.offset-lg-1 {
    margin-left: 8.33333333%;
}