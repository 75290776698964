.filter-container {
  font-family: inherit;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  
  @media screen and (max-width: 576px) {
    justify-content: space-between;
  }

  @media screen and (max-width: 344px) {
    justify-content: center;
  }

  flex-wrap: wrap;
}
.filter-button {
  font-family: inherit;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;

  position: static;
  min-width: 76px;
  height: 40px;
  left: 0px;
  top: 0px;

  /* Red */

  border-radius: 24px;

  /* Inside auto layout */

  flex: none;
  flex-grow: 0;
  margin: 0px 20px;
  color: #000;

  background-color: transparent;

  border-width: 1px;
  border-color: transparent;

  box-shadow: none;
  cursor: pointer;

  transition: border 0.3s ease, background 0.3s ease;

  &:hover {
    border: 1px solid #ff5f73;
  }

  &.active {
    color: #fff;
    background: #ff5f73;
  }
}
