.our-works {
    display: block;
    padding-bottom: 200px;
    .container{
        max-width: 1320px;
        width: 100%;
        padding-right: var(--bs-gutter-x,.75rem);
        padding-left: var(--bs-gutter-x,.75rem);
        margin-right: auto;
        margin-left: auto;
        .row{
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;
            display: flex;
            flex-wrap: wrap;
            margin-top: calc(-1 * var(--bs-gutter-y));
            margin-right: calc(-.5 * var(--bs-gutter-x));
            margin-left: calc(-.5 * var(--bs-gutter-x));
           .list{
                display: grid;
                column-gap: 80px;
                grid-auto-columns: 1fr;
                grid-auto-flow: column;
                margin: 0 -13px;
                .item{
                    padding: 36px 20px;
                    border: 1px solid #d6d6d7;
                    box-sizing: border-box;
                    .icon{
                        margin-bottom: 36px;
                    }
                    h3{
                        font-size: 24px;
                        margin-bottom: 20px;
                    }
                    .overview{
                        font-size: 14px;
                        line-height: 160%;
                        opacity: .6;
                    }
                }
           } 
           .col-12.col-lg-5{
                padding: 0px 40px;
            }
        }
    }
}
.col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
}