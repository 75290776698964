.MuiPaper-root {
  color: transparent;
}
.dots {
  position: absolute;
  bottom: -25px;
  display: block;
  // width: 100%;
  max-width: 500px;
  margin: 0 auto;

  padding: 0;
  // margin: 0;
  list-style: none;
  text-align: none;

  & li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    & button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;

      &:hover,
      &:focus {
        // opacity: 1;
        color: #ff5f73;
        -webkit-text-stroke: 0;
        outline: none;
        &:before {
          opacity: 1;
          -webkit-text-stroke: 1px #ff5f73;
        }
      }

      &:before {
        font-family: 'slick';
        font-size: 12px;
        line-height: 20px;

        position: absolute;
        top: 0;
        left: 0;

        width: 20px;
        height: 20px;

        content: '•';
        text-align: center;

        opacity: 0.25;
        // color: transparent;
        -webkit-text-stroke: 1px white;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
  & .slick-active button:before {
    opacity: 1;
    color: #ff5f73;
    -webkit-text-stroke: 1px #ff5f73;
  }

  // & li:first-child {
  // 	padding-left: 0;
  // }
}
