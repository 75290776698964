// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
}

.typo {
  height: fit-content;
  &-container {
    width: 70%;
    // // height: auto;
    margin: 0 auto;
  }
  &--normal {
    color: #ffffff;
    font-weight: bold;
    font-size: 4vw;
    margin-bottom: -6%;
    animation: slideTypoNormalToTop ease 1s;
  }
  &--outlined {
    font-style: normal;
    font-weight: bold;
    font-size: 11vw;

    text-transform: uppercase;
    -webkit-text-stroke: 1px #ffffff;
    animation: slideTypoOutlinedToTop ease 1s;

    span {
      transition: 0.3s;
    }
    span:hover {
      color: white;
      text-shadow: 0 0 5px white, 0 0 25px white, 0 0 50px white;
    }
  }
  &--outlined + &--outlined {
    margin-top: -9%;
  }
}

.no-copy p {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

@keyframes slideTypoNormalToTop {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideTypoOutlinedToTop {
  from {
    opacity: 0;
    transform: translateY(25%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotate-keyframes {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.arrow-button:hover {
  fill: #fff;
}

.typo--red {
  /* Roboto 14 Bold Big ranger */

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-transform: uppercase;

  /* Red */

  color: #ff5f73;

  @include sm {
    font-size: 13px;
    letter-spacing: 0.12em;
  }
  @include md {
    font-size: 15px;
    letter-spacing: 0.15em;
  }
  @include lg {
    font-size: 18px;
    letter-spacing: 0.18em;
  }
  @include xl {
    font-size: 18px;
    letter-spacing: 0.2em;
  }
  font-size: 12px;
  letter-spacing: 0.12em;
}

.button-primary {
  color: '#fff';
  text-decoration: 'none';
  font-size: 14px;
  font-weight: 300;
  background-color: #2daa67;
  border-radius: 25;
  margin: none;
  z-index: 1;
}
button {
  background: none;
}
button:focus {
  outline: none;
}
.color--primary {
  color: #2daa67;
}
