.projects-grid {
  padding-top: 30px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
}
.project-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  // cursor: pointer;
  height: 250px;

  @media screen and (max-width: 576px) {
    height: 200px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  &--overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      $color: (
        #ff5f73
      ),
      $alpha: 0.65
    );
    opacity: 0;
    transition: opacity 0.3s;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &:hover &--overlay {
    opacity: 1;
  }

  &--title {
    font-size: 30px;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }

    a {
      color: #fff;
      text-decoration: none;
      transition: all 0.3s;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--description {
    width: 100%;
    margin: 0 10px 0 10px;
    text-align: center;
    color: #fff;
    font-size: 16px;

    @media screen and (max-width: 576px) {
      font-size: 14px;
    }

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.project-cover {
  display: block;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
  object-fit: cover;
}
