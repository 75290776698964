.idea-block {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
  }

  &__wrapper {
    margin: auto;
    width: 90%;
    height: 450px;
    background-color: #f4f4f4;
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }

  &__left {
    flex: 1;
    margin-top: 130px;
    margin-left: 150px;
    margin-right: 50px;
    position: relative;
    &__title {
      line-height: 44px;
      font-size: 40px;
      color: #a0a0a1;
      font-weight: 600;
      margin-bottom: 40px;
      span {
        color: #0d0d0d;
      }
    }

    &__image {
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(50%);
    }

    &__button {
      display: inline-block;
      text-decoration: none;
      cursor: pointer;
      vertical-align: middle;
      font-size: 18px;
      background: #1a5dff;
      color: #fff;
      border-radius: 100px;
      padding: 12px 24px;
      text-align: center;
      border: 2px solid transparent;
      -webkit-transition: 0.5s all;
      transition: 0.5s all;
      font-weight: 500;
      padding-right: 50px;
      position: relative;

      &:hover {
        background-color: #fff;
        color: #1a5dff;
        border: 1px solid #1a5dff;

        & svg {
          stroke: #1a5dff;
        }
      }

      svg {
        position: absolute;
        right: 24px;
        top: 50%;
        stroke: #fff;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }

  &__right {
    flex: 1;
    position: relative;
    margin-right: 100px;
    &__image {
      position: absolute;
      top: -12%;
      left: 0;
      z-index: 10;
    }

    .circle {
      border-radius: 50%;
      background: #f4f4f4;
      position: absolute;
      z-index: 20;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 20;
      width: 100px;
      height: 100px;
    }
  }
}
