.projects {
  padding: 100px 0;
  background: #f4f4f4;

  &__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &__warpper {
    width: 40%;
  }
  &__about-us {
    margin-top: 145px;
    height: 40px;
    width: 100%;
  }

  &__sub-heading {
    font-size: 32px;
    margin-bottom: 45px;
    line-height: 1.1;
    font-weight: bold;
  }

  &__overview {
    font-size: 16px;
    margin-bottom: 16px;
    margin-bottom: 16px;
  }

  &__button {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    vertical-align: middle;
    font-size: 16px;
    background: #1a5dff;
    color: #fff;
    border-radius: 100px;
    padding: 12px 24px;
    text-align: center;
    border: 2px solid transparent;
    -webkit-transition: 0.5s all;
    transition: 0.5s all;
    font-weight: 500;
    padding-right: 50px;
    position: relative;

    &:hover {
      background-color: #fff;
      color: #1a5dff;
      border: 1px solid #1a5dff;

      & svg {
        stroke: #1a5dff;
      }
    }

    svg {
      position: absolute;
      right: 24px;
      top: 50%;
      stroke: #fff;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}
