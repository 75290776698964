.stories {
  margin: 86px 0 0 0;
  background: #0d0d0d url(../../assets/images/bg-stories.png) no-repeat;
  background-position: center bottom;
  padding: 123px 0 300px 0;
  &__container {
    width: 80%;
    margin: 0 auto;
  }

  &__heading {
    h2 {
      font-size: 48px;
      line-height: 110%;
      color: #fff;
      font-weight: 700;
      margin-bottom: 32px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;

    &__overview {
      flex: 1 0;
      font-size: 16px;
      line-height: 150%;
      color: #fff;
      opacity: 0.6;
      & p {
        margin-right: 100px;
      }
    }
    &__share {
      flex: 1.5 0;

      &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;
        margin-bottom: 10px;
      }
      &__button {
        display: inline-block;
        text-decoration: none;
        cursor: pointer;
        vertical-align: middle;
        font-size: 16px;
        background: #0d0d0d;
        color: #fff;
        border-radius: 100px;
        padding: 20px 24px;
        text-align: center;
        border: 1px solid #fff;
        -webkit-transition: 0.5s all;
        transition: 0.5s all;
        font-weight: 500;
        padding-right: 50px;
        position: relative;

        &:hover {
          background-color: #fff;
          color: #0d0d0d;
          border: 1px solid transparent;
          & svg {
            stroke: #0d0d0d;
          }
        }

        svg {
          position: absolute;
          right: 24px;
          top: 50%;
          stroke: #fff;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }

      &__container-slides {
        width: 600px;
        height: 400px;

        .swiper-button-prev {
          display: none;
        }

        .swiper-button-next {
          display: none;
        }
      }

      &__card {
        background-color: #1d1d25;
        padding: 56px;
        height: 250px;

        .title {
          color: #fff;
          margin-bottom: 8px;
          font-size: 16px;
        }
        .company {
          color: #fff;
          opacity: 0.6;
        }
        .icon {
          margin-bottom: 12px;
        }

        .text {
          margin-top: 36px;
        }

        .content {
          color: #fff;
          opacity: 0.6;
          margin-bottom: 16px;
          font-size: 16px;
        }
      }
    }
  }
}
