.portfolio {
  &-container {
    font-family: Roboto;
  }
  &__header {
    margin-top: 100px;

    @include md {
      margin-top: 150px;
    }
    &-text {
      text-align: center;
      font-size: 20px !important;
      font-weight: 500 !important;
      line-height: 1.25 !important;

      @include md {
        font-size: 30px !important;
      }
    }
  }

  &__projects {
    margin-top: 30px;

    @include md {
      margin-top: 80px;
    }
  }
}
